<template>
  <div>
    <el-card style="margin-bottom: 10px">
      <nav class="out">
        <nav class="input">
          <el-input
            v-model="querifo.viewUser"
            size="medium"
            placeholder="查看者昵称，姓名"
          />
        </nav>
        <nav class="input">
          <el-input
            v-model="querifo.viewedUser"
            size="medium"
            placeholder="被查看者昵称，姓名"
          />
        </nav>
        <nav class="input" style="width: 150px">
          <el-select
            v-model="querifo.terminal"
            placeholder="平台"
            size="medium"
          >
            <el-option label="全部" value=" " />
            <el-option
              v-for="(item, index) in terminal"
              :key="index"
              :label="item.value"
              :value="item.value"
            />
            <!-- <el-option label="wechat" value="wechat" /> -->
          </el-select>
        </nav>
        <label
          for=""
          style="font-size: 14px; float: left; transform: translateY(9px)"
          >查看时间：</label
        >
        <nav class="input" style="width:300px">
          <!-- <div class="box" style="width:300px"> -->
          <el-date-picker
            v-model="querifo.viewDate"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            style="width: 100%"
            size="medium"
          />
          <!-- </div> -->
        </nav>
        <nav class="input">
          <el-button size="small" @click="reset">重置</el-button>
          <el-button type="primary" size="small" @click="subimt">{{
            btn ? '搜索' : 'loading..'
          }}</el-button>
        </nav>
      </nav>
    </el-card>
    <el-card class="box-card">
      <el-table
        :data="userlist"
        border
        stripe
        :header-cell-style="rowClass"
        @sort-change="sortChange"
      >
        <!-- <el-table-column label="ID" prop="id" /> -->
        <el-table-column label="查看者" prop="viewUser">
          <template slot-scope="{ row }">
            <p>【UID:{{ row.userId }}】{{ row.viewUser }}</p>
          </template>
        </el-table-column>
        <el-table-column label="被查看者" prop="viewedUserId">
          <template slot-scope="{ row }">
            <p>【UID:{{ row.viewedUserId }}】{{ row.viewedUser }}</p>
          </template>
        </el-table-column>
        <el-table-column label="平台" prop="terminal" />
        <el-table-column label="查看时间" prop="createTime" />
      </el-table>
      <el-pagination
        :current-page="querifo.page"
        :page-size="querifo.pageSize"
        layout="total, prev, pager, next"
        :total="total"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>
    <el-dialog
      title="添加用户"
      :visible.sync="addDialogVisible"
      width="50%"
      @close="addColse"
    >
      <!-- 内容主题区域 -->
      <el-form
        ref="addFormRef"
        label-width="80px"
        :model="addform"
        :rules="addFormRulue"
      >
        <el-form-item label="用户名" prop="username">
          <el-input v-model="addform.username" />
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="addform.password" />
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="addform.email" />
        </el-form-item>
        <el-form-item label="手机" prop="mobile">
          <el-input v-model="addform.mobile" />
        </el-form-item>
      </el-form>

      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addUser">确 定</el-button>
      </span>
    </el-dialog>
    <dilog :dialog-visible.sync="shk" :form="shkform" @getlist="getuserlist" />
  </div>
</template>

<script>
import $ from 'jquery'
// import { getChannelList } from '../api/axiso.js'
import Remark from '../../components/remark/index'
import Dilog from '../../components/dilog.vue'
export default {
  components: {
    Remark,
    Dilog
  },
  data() {
    var checkEmail = (rule, value, cb) => {
      // 这是验证邮箱的正则表达式
      const regemail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (regemail.test(value)) {
        return cb()
      }
      cb(new Error('请输入合格邮箱'))
    }
    var checkMoblle = (rule, value, cb) => {
      const regphone = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/
      if (regphone.test(value)) {
        return cb()
      }
      cb(new Error('请输入合法的手机号'))
    }

    return {
      province: '',
      btn: true,
      baseurl: this.$store.state.env.cos,
      yu: '/' + this.$store.state.env.domain,
      shk: false,
      shkform: {},
      regDate: [],
      querifo: {
        page: 1,
        pageSize: 10,
        viewUser: '',
        viewedUser: '',
        terminal: '',
        viewDate: []
      },
      order: '',
      userlist: [],
      total: 0,
      addDialogVisible: false,
      addform: {
        username: '',
        password: '',
        email: '',
        mobile: ''
      },
      addFormRulue: {
        nickname: [
          { required: true, message: '请输入昵称', trigger: 'blur' }
          // {
          //   min: 3,
          //   max: 10,
          //   message: '用户的长度在3~10个字符直接',
          //   trigger: 'blur'
          // }
        ],
        realName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        businessInfo: [
          { required: true, message: '请输入业务介绍', trigger: 'blur' }
        ],
        company: [
          { required: true, message: '请输入公司名称', trigger: 'blur' }
        ],
        job: [{ required: true, message: '请输入职位', trigger: 'blur' }],
        province: [{ required: true, message: '请输入城市', trigger: 'blur' }],
        contactType: [
          { required: true, message: '请输入联系方式', trigger: 'blur' }
        ],
        type: [{ required: true, message: '请输入类型', trigger: 'blur' }],
        business: [{ required: true, message: '请输入行业', trigger: 'blur' }],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            min: 6,
            max: 15,
            message: '用户的长度在6~15个字符直接',
            trigger: 'blur'
          }
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          { validator: checkEmail, trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: checkMoblle, trigger: 'blur' }
        ],
        realname: [{ required: true, message: '请输入昵称', trigger: 'blur' }]
      },
      editdialog: false,
      editform: {
        realName: '',
        nickname: '',
        email: '',
        phone: '',
        remark: '',
        waId: '',
        weixin: '',
        businessInfo: ''
      },
      setRoledingvisable: false,
      userinfo: '',
      // 所有角色数据列表
      roleslist: [],
      slectroleName: '',
      Config: this.$store.state.config,
      status: '',
      service: '',
      paiform: {},
      header: {
        Token: sessionStorage.getItem('token')
      },
      action: {
        action: 'user'
      },
      city: '',
      terminal: []
    }
  },

  computed: {
    citys() {
      return async function(val) {
        console.log(val)

        const { data: res } = await this.$http.get(
          '/admin/Area/getList?code=' + val
        )
        console.log(res)
        return { code: 5, sds: 3 }
        // .then(({ data: res }) => {
        //   if (res.errorCode == 200) {
        //     console.log(1);

        //   }
        // })
      }
    }
  },
  created() {
    this.getuserlist()
    this.getTerminal()
  },
  mounted() {},
  methods: {
    op() {
      this.getc()
      this.editform.city = ''
    },
    getTerminal() {
      this.$http
        .get('/admin/Common/getConfigDictionaries?config=general&type=terminal')
        .then(({ data: res }) => {
          this.terminal = res.data
        })
    },
    showShk(row) {
      this.shk = true
      this.shkform = row
    },
    async handleInputBlur(row) {
      this.paiform.id = row.id
      this.paiform.remark = row.remark
      this.paiform.waId = row.waId.join(',')
      console.log(this.paiform)
      const { data: res } = await this.$http.post(
        '/admin/User/editInfo',
        this.paiform
      )
      if (res.errorCode == 200) {
        this.$message.success(res.message)
        //  row.pai=false
        //  this.tableData=[...this.tableData]
        this.getuserlist()
      }
    },
    rowClass() {
      return 'background:#F5F7FA;'
    },
    getc() {
      this.$http
        .get('/admin/Area/getList?code=' + this.editform.province)
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            console.log(this.editform.province)
            console.log(res)
            this.city = res.data
          }
        })
    },
    sortChange(c) {
      console.log(c)
      if (c.order) {
        var s = 'user_' + c.prop
        if (c.prop == 'orderNum') {
          s = 'activity_order_num'
        } else {
          s = 'user_' + c.prop
        }
        var p = c.order.slice(0, c.order.length - 6)
        this.order = s + ' ' + p
      } else {
        this.order = ''
      }

      this.getuserlist()
    },
    subimt() {
      this.querifo.page = 1
      this.getuserlist()
    },
    async getuserlist() {
      const { data: res } = await this.$http.get('/admin/User/circlesList', {
        params: {
          ...this.querifo,
          viewDate: this.querifo.viewDate.join('~'),
          terminal: this.querifo.terminal.trim()
        }
      })
      if (res.errorCode != 200) {
        return 
      }
      this.userlist = res.data.list

      this.total = res.data.totalCount
      // console.log(res)
    },
    handleSizeChange(e) {
      // console.log(e)
      this.querifo.pageSize = e
      this.getuserlist()
    },
    handleCurrentChange(e) {
      // console.log(e)
      this.querifo.page = e
      this.getuserlist()
    },
    async userstatechange(userinfo) {
      // 监听switch开关状态改变
      // console.log(userinfo)
      const { data: res } = await this.$http.put(
        `users/${userinfo.id}/state/${userinfo.mg_state}`
      )
      // console.log(res)
      if (res.meta.status != 200) {
        userinfo.mg_state = !userinfo.mg_state
        return this.$message.error('更新用户失败')
      }
      this.$message.success('更新用户成功')
    },
    addColse() {
      this.$refs.addFormRef.resetFields()
    },
    editclose() {
      this.$refs.editFormRef.resetFields()
    },
    addUser() {
      this.$refs.addFormRef.validate(async val => {
        // console.log(val)
        if (!val) return
        const { data: res } = await this.$http.post('users', this.addform)
        if (res.meta.status != 201) {
          this.$message.error('添加用户失败')
        }
        this.$message.success('添加用户成功')
        this.addDialogVisible = false
        this.getuserlist()
      })
    },
    reset() {
      this.querifo = {
        page: 1,
        pageSize: 10,
        viewUser: '',
        viewedUser: '',
        terminal: '',
        viewDate: []
      }
      this.getuserlist()
    },
    clear() {
      this.editform.business = []
      console.log(this.editform)
    },

    async removeuserid(id) {
      // console.log(id)
      await this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$http
            .delete('/admin/User/del', { params: { id: id } })
            .then(({ data: res }) => {
              if (res.errorCode == 200) {
                this.$message.success(res.message)
                if (this.total % 10 == 1) {
                  this.querifo.page--
                }
                this.getuserlist()
              }
            })
            .catch(() => {
              return this.$message.error('删除用户失败')
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 这是展示分配角色的对话框
    setRole(row, info) {
      this.$http
        .post('/admin/User/cert', {
          ...row,
          certStatus: info
        })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.$message.success(res.message)
            this.getuserlist()
          }
        })
    },
    saveinRole() {
      if (!this.slectroleName) {
        return this.$message.error('请选择你要分配的角色')
      }
      this.$http
        .put(`users/${this.userinfo.id}/role`, {
          rid: this.slectroleName
        })
        .then(() => {
          this.$message.success('更新角色成功')
          this.getuserlist()
          this.setRoledingvisable = false
        })
        .catch(() => {
          this.$message.error('更新角色失败!')
        })
    },
    setRoleclosed() {
      this.slectroleName = ''
    },
    handleAvatarSuccess(res) {
      this.editform.avatar = res.data.url
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  display: inline-block;
  margin-left: 15px;
}
.skyblue {
  color: skyblue;
}
.green {
  color: green;
}
.yellow {
  color: pink;
}
.red {
  color: red;
}
</style>
